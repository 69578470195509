:root {
  /* Sizes */
  --container-width: 1024px;

  /* Spacing */
  --smaller-spacing: .5rem;
  --small-spacing  : .75rem;
  --base-spacing   : 1rem;
  --medium-spacing : 1.5rem;
  --large-spacing  : 2rem;
  --larger-spacing : 3rem;

  /* Font size */
  --smaller-font-size: 0.750rem;
  --small-font-size  : 0.875rem;
  --base-font-size   : 1rem;
  --medium-font-size : 1.125rem;
  --large-font-size  : 1.5rem;

  /* Font weight */
  --font-weight-regular: 400;
  --font-weight-medium : 500;
  --font-weight-bold   : 700;
  --font-weight-black  : 900;

  /* Z-index */
  --bottom-z-index: 10;
  --base-z-index  : 20;
  --top-z-index   : 30;

  /* Colors */
  --black: #272727;
  --red  : #E74C3C;

  /* Border radius */
  --base-border-radius: 4px;

  /* Transition */
  --base-transition: all 200ms ease-in-out;
}
